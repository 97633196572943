




































































































































































































































































































































































































































































































































































































































































































































































import AuthService from "@/services/AuthService";
import EventService from "@/services/EventService";
import StaffService from "@/services/StaffService";
import moment from "moment";
import "moment/locale/de";
import { Component, Vue, Watch } from "vue-property-decorator";
import router from "../router/index";

@Component
export default class Report extends Vue {
  private root: string = process.env.VUE_APP_API_URL;
  private item: any = { event: {}, registrations: [] };
  private registration: any = null;
  private hasError: boolean = false;
  private error: string = "";
  private staff: any = null;
  private allstaff: any[] = [];
  private searchtext: string = "";
  private result: any[] = [];
  private deleteReservation: any = null;
  private sendMail: any = null;
  private services: any[] = [];
  private templateNames: any = {
    event: "Anwesenheitsliste",
    guestlist: "Registrierungen",
    seats: "Sitzplan",
    qrcode: "QRCode",
  };
  private mainProps = { width: 200, height: 200 };
  private uuid: string = router.currentRoute.params.event || "";
  private date: string = (router.currentRoute.query.date as string) || "";

  hasRoles(roles: any[]) {
    return AuthService.hasRoles(roles);
  }

  valid(item: any) {
    return (
      this.numberstate(item.leadtime, 0, 7) &&
      this.numberstate(item.opentime, 1, 200) &&
      this.numberstate(item.seats, 0, 1000)
    );
  }

  validGuest(i: number = -1) {
    let guest =
      i < 0
        ? this.registration.guests[this.registration.guests.length - 1]
        : this.registration.guests[i];

    if (this.item === "NAME") {
      return this.state(guest.firstName) && this.state(guest.lastName);
    } else if (this.item.registrationMode === "ADDRESS") {
      return i === 0
        ? this.state(guest.firstName) &&
            this.state(guest.lastName) &&
            this.state(guest.address.street) &&
            this.state(guest.address.zip) &&
            this.state(guest.address.city)
        : this.state(guest.firstName) && this.state(guest.lastName);
    } else if (this.item.registrationMode === "CONTACT") {
      return i === 0
        ? this.state(guest.firstName) &&
            this.state(guest.lastName) &&
            this.state(guest.contact.phone)
        : this.state(guest.firstName) && this.state(guest.lastName);
    } else if (this.item.registrationMode === "ADDRESS_CONTACT") {
      return i === 0
        ? this.state(guest.firstName) &&
            this.state(guest.lastName) &&
            this.state(guest.address.street) &&
            this.state(guest.address.zip) &&
            this.state(guest.address.city) &&
            this.state(guest.contact.phone)
        : this.state(guest.firstName) && this.state(guest.lastName);
    } else {
      return true;
    }
  }

  validGuests(guests: any) {
    return (
      guests.filter((g: any, i: number) => !this.validGuest(i)).length == 0
    );
  }

  contains(guests: any[], guest: any) {
    return (
      this.registration.guests.filter(
        (g: any) =>
          g.firstName === guest.firstName && g.lastName === guest.lastName
      ).length > 0
    );
  }

  selectGuest(guest: any) {
    if (!this.contains(this.registration.guests, guest)) {
      this.registration.guests = this.registration.guests
        .filter((g: any) => g.firstName && g.lastName)
        .map((g: any) => {
          g.uuid = null;
          return g;
        })
        .concat([guest]);
    }
  }

  selectRegistration(registration: any) {
    this.registration.guests = this.registration.guests
      .filter((g: any) => g.firstName && g.lastName)
      .map((g: any) => {
        g.uuid = null;
        return g;
      })
      .concat(
        registration.guests.filter(
          (g: any) => !this.contains(this.registration.guests, g)
        )
      );
  }

  customLabel(item: any) {
    return `${item.lastName}, ${item.firstName} – ${item.role}`;
  }

  reload() {
    EventService.getEvent(this.uuid, this.date).then((response) => {
      this.item = response.data;
    });
  }

  state(value: any) {
    if (!value) {
      return false;
    }

    return value.length > 0;
  }

  numberstate(value: number, min: number, max: number) {
    if (!value) {
      return false;
    }

    return value >= min && value <= max;
  }

  timestate(value: any, allowEmpty: boolean = false) {
    if (!value) {
      return allowEmpty ? null : false;
    }

    let match = value.match(/(\d{1,2}):(\d{1,2})/);

    if (!match) {
      return false;
    }

    let hour = Number(match[1]);
    let minute = Number(match[2]);

    return hour >= 0 && hour < 24 && minute >= 0 && minute < 60;
  }

  add() {
    this.registration.guests.push({
      lastName: this.registration.guests[0].lastName,
      address: {},
      contact: {},
      show: false,
    });
  }

  addstaff(staff: any) {
    if (!this.item.staff) {
      this.item.staff = [];
    }
    this.item.staff.push(this.allstaff.filter((s) => s.uuid === staff)[0]);
  }

  saveRegistration() {
    if (this.registration.uuid) {
      EventService.updateRegistration(this.registration).then((response) => {
        this.registration = null;
        this.reload();
      });
    } else {
      EventService.createRegistration(this.registration).then((response) => {
        this.registration = null;
        this.reload();
      });
    }
  }

  backRegistration() {
    this.registration = null;
  }

  addRegistration() {
    this.registration = {
      date: this.date,
      event: this.item.event,
      guests: [
        {
          address: { city: "Frankfurt" },
          contact: {},
        },
      ],
    };
  }

  save() {
    EventService.saveItem(this.item).then((response) => this.reload());
  }

  checkin(registration: string, guest: string, checkin: boolean) {
    EventService.checkin(registration, guest, checkin).then((response) =>
      this.reload()
    );
  }

  edit(registration: any) {
    this.registration = registration;
  }

  remove(registration: any) {
    EventService.remove(registration.uuid).then((response) => this.reload());
  }

  send(registration: any) {
    EventService.send(registration.uuid).then((response) => this.reload());
  }

  toDate(date: any) {
    return moment(date).format("dddd, DD.MM.YYYY");
  }

  toDate2(date: any) {
    return moment(date).format("YYYY-MM-DD");
  }

  back() {
    router.push({ name: "home" });
  }

  download(template: string) {
    EventService.download(this.uuid, this.date, template).then(
      (response: any) => {
        var downloadURL = URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        const anchor = document.createElement("a");
        anchor.download = `${moment(this.date).format(
          "YYMMDD"
        )}-${this.item.event.startTime.replace(":", "")}-${
          this.item.event.location.name
        }-${this.item.event.name}-${this.templateNames[template]}.pdf`;
        anchor.href = downloadURL;
        anchor.target = "_blank";
        anchor.click();
      }
    );
  }

  @Watch("searchtext")
  search() {
    EventService.searchRegistrations(this.searchtext).then(
      (response) => (this.result = response.data)
    );
  }

  mounted() {
    moment.locale("de");
    moment.weekdays(true);

    AuthService.services().then(
      (response) =>
        (this.services = response.data.map(
          (s: string) => <any>{ value: s, text: s }
        ))
    );

    this.reload();
    StaffService.all().then(
      (response) =>
        (this.allstaff = response.data.sort((a: any, b: any) =>
          a.role === b.role
            ? (a.lastName > b.lastName && 1) || -1
            : (a.role > b.role && 1) || -1
        ))
    );
  }
}
